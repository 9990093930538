<template>
    <LayoutNext>
        <template #page-title>
            Pex Copies
        </template>
        <CopiesComponentFilter v-model="copiesFilter" />
        <CopiesComponentFiltersModals />
        <MDBCard class="shadow-0 card-container" id="CopiesComponent" fluid>
            <MDBCardHeader class="d-md-flex d-block justify-content-between py-3 px-0">
                <div class="d-flex justify-content-start gap-1 mb-3 mb-md-0">
                    <MDBBtn class="fw-bold px-3 py-2" size="sm" color="primary" @click="createCollectionModal = true">
                        <MDBIcon icon="folder-plus" iconStyle="fas" class="me-1" />
                        Create Collection
                    </MDBBtn>
                    <MDBBtn class="fw-bold px-3 py-2" size="sm" color="primary" @click="goToAsset">
                        <MDBIcon icon="plus-square" class="me-1" />
                        Add Asset(s)
                    </MDBBtn>
                    <!-- <select class="form-select" style="height: fit-content; width: fit-content">
                        <option value="">Select Filter</option>
                        <optgroup label="Source">
                            <option>Collection</option>
                            <option>Velociraptor</option>
                            <option>Deinonychus</option>
                        </optgroup>
                        <optgroup label="Sauropods">
                            <option>Diplodocus</option>
                            <option>Saltasaurus</option>
                            <option>Apatosaurus</option>
                        </optgroup>
                    </select> -->
                </div>
                <div class="d-flex justify-content-end gap-1">
                    <MDBBtn class="fw-bold px-3 py-2" size="sm" color="primary" @click="copiesFilter = !copiesFilter">
                        <MDBIcon icon="filter" iconStyle="fas" class="me-1" />
                        Manage Filters
                    </MDBBtn>
                    <MDBBtn class="fw-bold px-3 py-2" size="sm" color="primary" :disabled="!isBulkActionEnable"
                        @click="bulkActionModal = true">
                        <MDBIcon icon="list" iconStyle="fas" class="me-1" />
                        Bulk Action
                    </MDBBtn>
                    <MDBBtn class="fw-bold px-3 py-2" size="sm" color="primary" @click="downloadModal = true">
                        <MDBIcon icon="arrow-down" iconStyle="fas" class="me-1" />
                        Download
                    </MDBBtn>
                </div>
            </MDBCardHeader>
            <MDBCardBody class="d-flex flex-column p-0">
                <h6 v-if="copiesTableRef" class="mt-4">
                    About
                    <strong>{{ copiesTableRef?.copyListTotalCount }}</strong>
                    Copies
                </h6>
                <p v-if="isBulkActionEnable" class="mb-3">
                    {{ copiesText }}
                </p>
                <CopiesComponentTable ref="copiesTableRef" />
            </MDBCardBody>
        </MDBCard>
        <!-- bulk actions modal -->
        <MDBModal tag="form" id="bulkActionModal" tabindex="-1" labelledby="bulkActionModalLabel"
            v-model="bulkActionModal">
            <MDBModalHeader class="px-4 py-3">
                <MDBModalTitle class="fw-bold" id="bulkActionModalLabel"> Bulk Actions </MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody class="px-4">
                <MDBBtn class="fw-bold" color="primary" @click="markAsExternallyAction">
                    <template v-if="bulkActionLoading"> <span>Marking as Externally Actioned...</span> </template>
                    <template v-else> <span>Mark as Externally Actioned</span> </template>
                </MDBBtn>
            </MDBModalBody>
            <MDBModalFooter class="py-3">
                <MDBBtn class="fw-bold" size="sm" color="tertiary" @click="bulkActionModal = false">Close</MDBBtn>
            </MDBModalFooter>
        </MDBModal>
        <MDBToast v-model="bulkActionToast" :delay="2000" autohide position="top-right" appendToBody stacking
            width="350px" color="success" text="white" icon="fas fa-check fa-lg me-2">
            <template #title> Success </template>
            Selected Copies Updated Successfully
        </MDBToast>
        <MDBModal tag="form" id="createCollectionModal" tabindex="-1" labelledby="CreateCollectionLabel"
            v-model="createCollectionModal" :static-backdrop="true">
            <MDBModalHeader class="py-3 px-4">
                <MDBModalTitle class="fw-bold" id="CreateCollectionLabel">
                    Create Collection
                </MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody class="p-4 pt-3">
                <p>You can manually add Assets to a Collection</p>
                <MDBInput class="mb-2" placeholder="Enter Collection Name *" type="text" v-model="collectionName"
                    required invalidFeedback="Please provide your first name" />
                <MDBInput placeholder="Enter Reference ID" type="text" v-model="referenceId" />
            </MDBModalBody>
            <MDBModalFooter class="py-3 gap-2">
                <MDBBtn class="fw-bold" size="sm" color="tertiary" @click="createCollectionModal = false">
                    Close
                </MDBBtn>
                <MDBBtn class="fw-bold" size="sm" type="button" color="primary" :disabled="collectionName == ''"
                    @click.prevent.stop="createCollection">
                    <span v-if="createCollectionModalLoader" size="sm">Creating...</span>
                    <span v-else>Create Collection</span>
                </MDBBtn>
            </MDBModalFooter>
        </MDBModal>
        <MDBToast v-model="collectionToast" :delay="2000" autohide position="top-right" appendToBody stacking
            width="350px" color="success" text="white" icon="fas fa-check fa-lg me-2">
            <template #title> Success </template>
            Collection Created Successfully
        </MDBToast>
        <MDBModal tag="form" id="downloadModal" tabindex="-1" labelledby="downloadModalLabel" v-model="downloadModal">
            <MDBModalHeader class="px-4 py-3">
                <MDBModalTitle class="fw-bold" id="downloadModalLabel"> Download </MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody class="px-4">
                <p class="m-auto">
                    Download all
                    <strong>{{ copiesTableRef?.copyListTotalCount }}</strong> Copies?
                </p>
            </MDBModalBody>
            <MDBModalFooter class="py-3 gap-2">
                <MDBBtn class="fw-bold" size="sm" color="tertiary" @click="downloadModal = false"> Close </MDBBtn>
                <MDBBtn class="fw-bold" size="sm" type="button" color="primary" @click.prevent.stop="download">
                    <span v-if="downloadModalLoader" size="sm">Downloading...</span>
                    <span v-else>Download</span>
                </MDBBtn>
            </MDBModalFooter>
        </MDBModal>
        <MDBToast v-model="downloadToast" :delay="2000" autohide position="top-right" appendToBody stacking
            width="350px" color="success" text="white" icon="fas fa-check fa-lg me-2">
            <template #title> Success </template>
            {{ copiesTableRef?.copyListTotalCount }} Downloaded Successfully
        </MDBToast>
    </LayoutNext>
</template>

<script setup>
import { MDBCard, MDBCardHeader, MDBCardBody, MDBBtn, MDBIcon, MDBModal, MDBModalHeader, MDBModalTitle, MDBModalBody, MDBModalFooter, MDBInput, MDBToast } from "mdb-vue-ui-kit";
import LayoutNext from "@/views/v3/LayoutNext.vue";
import { computed, ref, watch } from "vue";
import { useRouter } from "vue-router";
import CopiesComponentFilter from "@/components/CopiesComponentFilter.vue";
import CopiesComponentTable from "@/components/CopiesComponentTable.vue";
import CopiesComponentFiltersModals from "@/components/CopiesComponentFiltersModals.vue";
import { PostCollection } from "@/services/Collections/PostCollection";
import { PatchCopiesStatusBulkUpdate } from "@/services/Copies/PatchCopiesStatusBulkUpdate";
import { PatchCopiesStatusBulkUpdateFilter } from "@/services/Copies/PatchCopiesStatusBulkUpdateFilter";
import { useCopiesFilter } from "@/store/copiesFilter";
import { storeToRefs } from "pinia";
import { PostExportCopies } from "@/services/Copies/PostExportCopies";
import { useTitle } from "@vueuse/core";

useTitle("Pex Copies | CreatorShield");

const copiesFilterStore = useCopiesFilter();
const { copiesFilter, filtersSet } = storeToRefs(copiesFilterStore);

const collectionToast = ref(false);
const createCollectionModal = ref(false);
const createCollectionModalLoader = ref(false);
const collectionName = ref("");
const referenceId = ref("");
const createCollection = async () => {
    const formBody = {
        title: collectionName.value,
        referenceId: referenceId.value,
    };
    createCollectionModalLoader.value = true;
    try {
        await PostCollection(formBody);
        collectionToast.value = true;
    } catch (error) {
        console.error("Failed to create collection: ", error);
    } finally {
        createCollectionModalLoader.value = false;
        createCollectionModal.value = false;
    }
};
watch(createCollectionModal, () => {
    collectionName.value = "";
    referenceId.value = "";
});
const router = useRouter();
const goToAsset = () => {
    router.push({ name: "AssetsUpload" });
};
const copiesTableRef = ref();
const isBulkActionEnable = computed(() => {
    return (
        (copiesTableRef.value && copiesTableRef.value?.selectAll) ||
        copiesTableRef.value?.selectedList.length > 0
    );
});
const bulkActionModal = ref(false);
const bulkActionLoading = ref(false);
const bulkActionToast = ref(false);
const markAsExternallyAction = async () => {
    try {
        bulkActionLoading.value = true;

        if (!copiesTableRef.value?.selectAll) {
            const selectedList = copiesTableRef.value?.selectedList;
            const selectedListIds = selectedList.map((list) => {
                return list.matchIdString;
            });
            bulkActionLoading.value = true;
            await PatchCopiesStatusBulkUpdate({
                ids: selectedListIds,
                status: 30,
            });
        } else {
            await PatchCopiesStatusBulkUpdateFilter({
                filtersSet: { ...filtersSet.value },
                status: 30,
            });
        }
    } catch (error) {
        console.error("Mark as Externally Action Err: ", error);
    } finally {
        bulkActionLoading.value = false;
        bulkActionModal.value = false;
        bulkActionToast.value = true;
        copiesTableRef.value?.setInitialCopies();
    }
};
const downloadModal = ref(false);
const downloadModalLoader = ref(false);
const downloadToast = ref(false);
const download = async () => {
    downloadModalLoader.value = true;
    const params = {
        ...(filtersSet.value ? { filters: { ...filtersSet.value } } : {}),
    };
    const response = await PostExportCopies(params);
    const fileURL = window.URL.createObjectURL(
        new Blob([response], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
    );
    const fileLink = document.createElement("a");

    fileLink.href = fileURL;
    fileLink.setAttribute(
        "download",
        `CreatorShield-Copies-${new Date(new Date()).toISOString().split("T")[0]
        }.csv`
    );
    document.body.appendChild(fileLink);
    fileLink.click();
    downloadModalLoader.value = false;
    downloadModal.value = false;
    downloadToast.value = true;
};
const copiesText = computed(() => {
    const copiesNumber = copiesTableRef.value?.selectAll
        ? copiesTableRef.value?.copyListTotalCount -
        copiesTableRef.value?.unSelectedList.length
        : copiesTableRef.value?.selectedList.length;

    return `${copiesNumber} copies selected.`;
});
</script>

<style scoped>
.btn-primary {
    background-color: var(--primary);
}

.btn-outline-primary {
    border-color: var(--accent);
    color: var(--accent);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 10px 0 rgba(0, 0, 0, 0.1);
}

#CopiesComponent .title {
    margin: 0;
    color: var(--accent);
}

#CopiesComponent .actions {
    margin-right: auto;
}

#CopiesComponent .actions .solid {
    background-color: var(--accent);
    border-color: var(--accent);
    color: white;
}

#CopiesComponent .actions .solid:hover {
    background-color: var(--primary);
    border-color: var(--primary);
}

#CopiesComponent .actions .outlined {
    color: var(--accent);
    border-color: var(--accent);
}

#CopiesComponent .actions .outlined:hover {
    background-color: var(--primary);
    color: white;
}

.card-container {
    flex: 1 1 auto;
    overflow: auto;
}

.image-container {
    position: relative;
    display: inline-block;
}

.image-container .loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 40px;
    height: 40px;
    border: 3px solid rgba(0, 0, 0, 0.1);
    border-top-color: rgb(153, 153, 153);
    border-radius: 50%;
    animation: BorderSpin 1s linear infinite;
}

.image-container img {
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

@keyframes BorderSpin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>
